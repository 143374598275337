<template>
    <div id="about">
        <!--        <img :src="require('@/assets/images/about/bg.png')" alt="" class="bg">-->
        <div class="content">
            <div class="top">
                <img :src="require('@/assets/images/about/pic.png')" alt="">
            </div>
            <div class="intr" v-if="data && data.gsjj">
                <h2>{{ isEn ? data.gsjj.enName : data.gsjj.cnName }}</h2>
                <div class="lf" v-if="data.gsjj.data">
                    <h3>{{ isEn ? data.gsjj.data.enTitle : data.gsjj.data.cnTitle }}</h3>
                    <p>{{ isEn ? data.gsjj.data.enContentSummary : data.gsjj.data.cnContentSummary }}</p>
                    <router-link :to="data.gsjj.templateUrl" class="icon-more"><span>{{ $t('lang.see_more') }}</span></router-link>
                </div>
                <div class="rf" v-if="data.gsjj.data">
                    <img :src="staticPath + (isEn ? data.gsjj.data.enLogo : data.gsjj.data.cnLogo)" alt="">
                </div>
            </div>
            <div :style="{'visibility': aniObj.cultureVisible ? 'visible' : 'hidden'}" :class="['culture', 'animate__animated', aniObj.culture ? 'animate__fadeInUp' : '']" ref="culture" v-if="data && data.qywh">
                <!--            <div :style="{'visibility': aniObj.cultureVisible ? 'visible' : 'hidden'}" :class="['culture', 'animate__animated', aniObj.culture ? 'animate__fadeInUp' : '']" ref="culture" v-if="data && data.qywh.data">-->
                <h2 class="badge">{{ isEn ? data.qywh.enName : data.qywh.cnName }}</h2>
                <div class="box">
                    <!--                    <img :src="staticPath + (isEn ? data.qywh.enLogo : data.qywh.cnLogo)" alt="" class="bg">-->
                    <!--                    <div class="text">-->
                    <!--                        <h3>{{ isEn ? data.qywh.data.enTitle : data.qywh.data.cnTitle }}</h3>-->
                    <!--                        <h4>{{ isEn ? data.qywh.data.enTitle : data.qywh.data.cnTitle }}</h4>-->
                    <!--                        <span>{{ data.qywh.data.enTitle }}</span>-->
                    <img :src="staticPath + (isEn ? data.qywh.data.enLogo : data.qywh.data.cnLogo)" alt="">
                    <!--                        <p v-html="isEn ? data.qywh.data.enContent : data.qywh.data.cnContent"></p>-->
                    <router-link :to="data.qywh.templateUrl" class="icon-more"></router-link>
                    <!--                    </div>-->
                </div>
            </div>
            <div :style="{'visibility': aniObj.honorVisible ? 'visible' : 'hidden'}" :class="['honor', 'animate__animated', aniObj.honor ? 'animate__fadeInUp' : '']" ref="honor" v-if="data && data.qyry">
                <h2 class="badge">{{ isEn ? data.qyry.enName : data.qyry.cnName }}</h2>
                <div class="honor-list" v-if="data.qyry.data">
                    <swiper ref="mySwiper" :options="swiperOptions">
                        <swiper-slide v-for="(item, index) in data.qyry.data.files" :key="index">
                            <div class="pic">
                                <img :src="staticPath + item.fileUrl" @click="showImgPreview(item.fileUrl)" alt />
                            </div>
                            <p>{{ isEn ? item.fileEnName : item.fileCnName }}</p>
                        </swiper-slide>
                        <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>
                        <div class="swiper-button-prev" slot="button-prev"></div>
                        <div class="swiper-button-next" slot="button-next"></div>
                    </swiper>
                </div>
            </div>
            <div :style="{'visibility': aniObj.processVisible ? 'visible' : 'hidden'}" :class="['process', 'animate__animated', aniObj.process ? 'animate__fadeInUp' : '']" ref="process" v-if="data && data.fzlc">
                <h2 class="badge">{{ isEn ? data.fzlc.enName : data.fzlc.cnName }}</h2>
                <div class="time-line time-phone" ref="timeline" v-if="data.fzlc.data">
                    <ul :style="{width: calcTimelineLength}">
                        <li @click="chooseYear(index)" v-for="(item, index) in data.fzlc.data" :key="index">
                            <strong :class="processTimelineIndex === index ? 'actived' : ''">{{ isEn ? item.enTitle : item.cnTitle }}</strong>
                            <div :class="['time-point', processTimelineIndex === index ? 'time-point-actived' : '']"></div>
                        </li>
                    </ul>
                </div>
                <div class="process-content" v-if="data.fzlc.data">
                    <div class="lf">
                        <img :src="staticPath + (isEn ? data.fzlc.data[processTimelineIndex].enLogo : data.fzlc.data[processTimelineIndex].cnLogo)" alt="">
                    </div>
                    <div class="rf">
                        <h1>{{ isEn ? data.fzlc.data[processTimelineIndex].enTitle : data.fzlc.data[processTimelineIndex].cnTitle }}</h1>
                        <span class="point"></span>
                        <p v-html="isEn ? data.fzlc.data[processTimelineIndex].enContent : data.fzlc.data[processTimelineIndex].cnContent"></p>
                        <!--            <p v-html="processTimeline[processTimelineIndex].events.replace(/\n/ig, '<br/>')"></p>-->
                    </div>
                </div>
                <div class="time-line" ref="timeline" v-if="data.fzlc.data">
                    <ul :style="{width: calcTimelineLength}">
                        <li v-for="(item, index) in data.fzlc.data" :key="index">
                            <strong :class="processTimelineIndex === index ? 'actived' : ''">{{ isEn ? item.enTitle : item.cnTitle }}</strong>
                            <div @click="chooseYear(index)" :class="['time-point', processTimelineIndex === index ? 'time-point-actived' : '']"></div>
                        </li>
                    </ul>
                </div>
            </div>
            <div :style="{'visibility': aniObj.developVisible ? 'visible' : 'hidden'}" :class="['develop', 'animate__animated', aniObj.develop ? 'animate__fadeInUp' : '']" ref="develop" v-if="data && data.fzzl">
                <div class="lf">
                    <img :src="staticPath + (isEn ? data.fzzl.enLogo : data.fzzl.cnLogo)" alt="">
                </div>
                <div class="rf" v-if="data.fzzl.data">
                    <h2 class="badge">{{ isEn ? data.fzzl.enName : data.fzzl.cnName }}</h2>
                    <div class="box">
                        <p>{{ isEn ? data.fzzl.data.enContentSummary : data.fzzl.data.cnContentSummary }}</p>
                        <router-link :to="data.fzzl.templateUrl" class="icon-more"><span>{{ $t('lang.see_more') }}</span></router-link>
                    </div>
                </div>
            </div>
            <div :style="{'visibility': aniObj.otheritemsVisible ? 'visible' : 'hidden'}" :class="['other-items', 'animate__animated', aniObj.otheritems ? 'animate__fadeInUp' : '']" ref="otheritems" v-if="data && data.redirect">
                <ul v-if="data.redirect">
                    <li v-for="(item, index) in data.redirect" :key="index">
                        <img :src="staticPath + (isEn ? item.enLogo : item.cnLogo)" alt="">
                        <h2>{{ isEn ? item.enName : item.cnName }}</h2>
                        <!--            <p>{{ item.msg }}</p>-->
                        <router-link :to="item.templateUrl" class="icon-more"></router-link>
                    </li>
                </ul>
            </div>
        </div>

        <!-- 组件 -->
        <img-preview v-model="isShowImgPreview" :imgPath="imgPreviewPath"></img-preview>
    </div>
</template>

<script>

    import "swiper/dist/css/swiper.css";
    import { swiper, swiperSlide } from "vue-awesome-swiper";
    import {urls, staticPath} from '@/lib/common';
    import imgPreview from '@components/imgPreview';


    export default {
        name: "About",
        components: {
            imgPreview,
            swiper,
            swiperSlide
        },
        computed: {
            swiper() {
                return this.$refs.mySwiper.$swiper;
            },
            calcTimelineLength() {
                if(document.body.clientWidth > 750)
                    return this.data.fzlc.data.length * 15 + 'vw';
                else
                    return this.data.fzlc.data.length * 25 + 6 + '%';
            }
        },
        watch: {
            "$i18n.locale": function(val) {
                val === "en" ? this.isEn = true : this.isEn = false;
            }
        },
        data() {
            return {
                isShowImgPreview: false,
                imgPreviewPath: '',

                swiperOptions: {
                    slidesPerView: document.body.clientWidth > 750 ? 3 : 2,
                    slidesPerColumn: 2,
                    pagination: {
                        el: ".swiper-pagination",
                        clickable: true
                    },
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    }
                },
                staticPath,
                tempId: 2,
                isEn: false,
                processTimelineIndex: 0,
                stepWidth: 0,
                data: "",
                cultureScrollTop: '',
                honorScrollTop: '',
                processScrollTop: '',
                developScrollTop: '',
                otheritemsScrollTop: '',
                aniObj: {
                    cultureVisible: false,
                    culture: false,
                    honorVisible: false,
                    honor: false,
                    processVisible: false,
                    process: false,
                    developVisible: false,
                    develop: false,
                    otheritemsVisible: false,
                    otheritems: false,
                },

            }
        },
        methods: {
            chooseYear(index) { // 发展历程-选择年份
                const timeline = this.$refs.timeline;
                const currPosition = timeline.children[0].children[index].offsetLeft;
                const liWidth = timeline.children[0].children[0].offsetWidth;
                this.processTimelineIndex = index;
                if (index >= 3) {
                    let timer = setInterval(() => {
                        if (this.stepWidth >= currPosition - liWidth / 2) {
                            clearInterval(timer);
                            timer = null;
                        } else {
                            timeline.scrollTo(this.stepWidth+=(liWidth/10), 0)
                        }
                    }, 10)
                } else {
                    let timer = setInterval(() => {
                        if (this.stepWidth + liWidth / 2 < currPosition - liWidth / 2) {
                            clearInterval(timer);
                            timer = null;
                        } else {
                            timeline.scrollTo(this.stepWidth-=(liWidth/10), 0)
                        }
                    }, 10)
                }
            },
            getTemplates() {
                return this.$http.get(urls.getAboutTemplate + this.tempId).then(res => {
                    if (res.data.status === 200) {
                        this.data = res.data.data;
                        if (this.data.fzlc && this.data.fzlc.data) {
                            var sortNumber = (a,b) => {
                                return (this.isEn ? b.enTitle - a.enTitle : b.cnTitle - a.cnTitle)
                            }
                            this.data.fzlc.data.sort(sortNumber)
                        }
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            },

            // 监听页面滚动
            getScrollTop() {
                var scroll_top = 0;
                if (document.documentElement && document.documentElement.scrollTop) {
                    scroll_top = document.documentElement.scrollTop;
                } else if (document.body) {
                    scroll_top = document.body.scrollTop;
                }
                return scroll_top;
            },
            listenPageScrollFun() {
                document.addEventListener('scroll', this.zoomControl, true)
            },
            zoomControl() {
                var diffValue = -(document.body.clientHeight * 0.5);

                this.winScrollHeight = this.getScrollTop();

                // if (this.cultureScrollTop < this.winScrollHeight + 600) {
                if (!this.aniObj.culture && this.winScrollHeight >= this.$refs.culture.offsetTop + diffValue) {
                    this.aniObj.culture = true;
                    this.aniObj.cultureVisible = true;
                }
                //if (this.honorScrollTop < this.winScrollHeight + 650) {
                if (!this.aniObj.honor && this.winScrollHeight >= this.$refs.honor.offsetTop + diffValue) {
                    this.aniObj.honor = true;
                    this.aniObj.honorVisible = true;
                }
                //if (this.processScrollTop < this.winScrollHeight + 650) {
                if (!this.aniObj.process && this.winScrollHeight >= this.$refs.process.offsetTop + diffValue) {
                    this.aniObj.process = true;
                    this.aniObj.processVisible = true;
                }
                //if (this.developScrollTop < this.winScrollHeight + 650) {
                if (!this.aniObj.develop && this.winScrollHeight >= this.$refs.develop.offsetTop + diffValue) {
                    this.aniObj.develop = true;
                    this.aniObj.developVisible = true;
                }
                //if (this.otheritemsScrollTop < this.winScrollHeight + 600) {
                if (!this.aniObj.otheritems && this.winScrollHeight >= this.$refs.otheritems.offsetTop + diffValue) {
                    this.aniObj.otheritems = true;
                    this.aniObj.otheritemsVisible = true;
                }
            },


            showImgPreview: function(imageSrc) {
                console.log(imageSrc);
                this.imgPreviewPath = this.man.fast.getResourcePath(imageSrc);
                this.isShowImgPreview = true;
            },
        },
        beforeDestroy() {
            document.removeEventListener('scroll', this.zoomControl, true)
        },
        created() {

        },
        mounted() {
            this.getTemplates().then(() => {
                this.cultureScrollTop = this.$refs.culture.offsetTop;
                this.honorScrollTop = this.$refs.honor.offsetTop;
                this.processScrollTop = this.$refs.process.offsetTop;
                this.developScrollTop = this.$refs.develop.offsetTop;
                this.otheritemsScrollTop = this.$refs.otheritems.offsetTop;
                this.listenPageScrollFun()
            })
        }
    };

</script>

<style lang="less" scoped>
    @deep: ~'>>>';
    @pointWidth: 13vw;
    #about {
        .content {
            width: 100%;
            z-index: 99;
            .top {
                background: url('../../assets/images/about/block/1.png') no-repeat left top;
                background-size: 100% 100%;
                width: 100%;
                max-height: 669px;
                margin: 0 auto;
                text-align: right;
                img {
                    width: 70%;
                }
            }
            .icon-more {
                display: inline-block;
                width: 28px;
                height: 18px;
                background: url('../../assets/images/about/icon_more.png') no-repeat center center;
                background-size: 100% 100%;
                span{display: none}
            }
            .intr {
                background: url('../../assets/images/about/block/2.png') no-repeat left top;
                background-size: 100% 100%;
                width: 100%;
                margin: 0 auto;
                padding: 2vw 12vw 0;
                color: #353968;

                >h2 {
                    position: relative;
                    font-size: 28px;
                }
                &::after {
                    content: '';
                    display: block;
                    clear: both;
                }
                .lf {
                    float: left;
                    width: 65%;
                    line-height: 3.125vw;
                    h3 {
                        font-size: 24px;
                        margin: 2vw 0;
                    }
                    p {
                        color: #343750;
                        font-size: 18px;
                        text-indent: 2em;
                        padding-right: 2em;
                        margin-bottom: 20px;
                        display: -webkit-box;
                        overflow: hidden;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 5;
                    }
                }
                .rf {
                    float: right;
                    width: 35%;
                }
            }
            .badge {
                font-size: 28px;
                color:#353968;
                text-align: center;
                &::after {
                    content: '';
                    display: inline-block;
                    width: 40px;
                    height: 34px;
                    background: url('../../assets/images/about/icon.png') no-repeat center center;
                    background-size: 100% 100%;
                    position: relative;
                    top: -0.8vw;
                    right: 0.3vw;
                }
            }
            .culture {
                background: url('../../assets/images/about/block/3.png') no-repeat left top;
                background-size: 100% 100%;
                //background-color: #00A66C;
                width: 100%;
                margin: 0 auto;
                padding: 5vw 15vw 0;
                color:#353968;

                >h2 {
                    font-size: 28px;
                }
                .box {
                    margin-top: 1.5vw;
                    text-align: center;

                    img {width: 100%; max-width: 60vw;}
                }
            }
            .honor {
                background: url('../../assets/images/about/block/4.png') no-repeat left top;
                background-size: 100% 100%;
                width: 100%;
                margin: 0 auto;
                padding: 5vw 15vw 1vw;

                >h2 {
                    color: #fff;
                    font-size: 28px;
                }
                .honor-list {
                    margin: 3vw auto;
                    font-size: 1.458vw;
                    text-align: center;
                    color: #fff;
                    .pic {
                        height: 16vw;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;

                        &::before,
                        &::after {
                            content: '';
                            display: inline-block;
                            width: 2.7vw;
                            height: 6.5vw;
                            position: relative;
                            top: -0.5vw;
                            left: 0.8vw;
                            background: url('../../assets/images/about/icon_wheat_left.png') no-repeat center center;
                            background-size: 100%;
                        }
                        &::after {
                            left: -0.8vw;
                            background-image: url('../../assets/images/about/icon_wheat_right.png');
                        }
                        img {
                            width: 11vw;
                            margin: 0 2vw;
                            cursor: pointer;
                        }
                    }
                    p {
                        width: 14vw;
                        font-size: 18px;
                        margin: 1vw auto 0;
                        text-align: center;
                    }
                    .swiper-container {
                        padding: 0 1.85vw 3vw !important;
                    }
                    .swiper-slide:nth-child(even) {
                        margin-top: 2vw !important;
                    }
                    .swiper-button-next {
                        width: 1.3vw;
                        height: 2.29vw;
                        background-image: url('../../assets/images/about/icon_arrow_right.png');
                        background-size: 100%;
                    }
                    .swiper-button-prev {
                        width: 1.3vw;
                        height: 2.29vw;
                        background-image: url('../../assets/images/about/icon_arrow_left.png');
                        background-size: 100%;
                    }
                    .swiper-pagination {
                        bottom: 0;
                    }
                }
            }

            .process {
                background: url('../../assets/images/about/block/5.png') no-repeat left top;
                background-size: 100% 100%;
                width: 100%;
                padding: 5vw 0;

                .process-content {
                    width: 60vw;
                    margin: 5vw auto 0;
                    display: flex;
                    justify-content: flex-start;
                    .lf {
                        width: 30vw;
                        height: 30vw;
                        box-sizing: border-box;
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .rf {
                        width: 30vw;
                        height: 30vw;
                        background: #1BB2FF;
                        box-sizing: border-box;
                        padding: 4vw 2vw 4vw 4vw;
                        color: #fff;
                        h1 {
                            font-size: 5vw;
                        }
                        .point {
                            display: inline-block;
                            width: 1.5vw;
                            height: 1.5vw;
                            background: #fff;
                            border-radius: 50%;
                            margin: 2vw 0;
                        }
                        p {
                            font-size: 1.2vw;
                            line-height: 2.2vw;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 6;
                            -webkit-box-orient: vertical;
                        }
                    }
                }
                .time-line {
                    margin-top: 3vw;
                    padding: 3vw;
                    overflow-x: scroll;
                    &::-webkit-scrollbar {
                        display: none;
                    }
                    ul {
                        margin-left: @pointWidth;
                        &::after {
                            content: '';
                            display: block;
                            clear: both;
                        }
                        li {
                            float: left;
                            text-align: center;
                            width: @pointWidth;
                            padding-top: 5vw;
                            position: relative;
                            strong {
                                font-size: 3.5vw;
                                color: #8ED9FF;
                                position: absolute;
                                left: 0.5em;
                                top: -0.1em;
                                &.actived {
                                    left: 0;
                                    top: -0.5em;
                                    font-size: 5vw;
                                    color: #1BB2FF;
                                }
                            }
                            .time-point {
                                width: 100%;
                                height: 0.2vw;
                                background: #8ED9FF;
                                position: relative;
                                cursor: pointer;
                                &::after {
                                    content: '';
                                    display: inline-block;
                                    width: 2.7vw;
                                    height: 2.7vw;
                                    background: #8ED9FF;
                                    border-radius: 50%;
                                    position: absolute;
                                    left: 50%;
                                    margin-left: -1.35vw;
                                    top: -1.35vw;
                                }
                            }
                            .time-point-actived {
                                &::after {
                                    background: #1BB2FF;
                                }
                            }
                        }
                    }
                }
                .time-phone{
                    display: none;
                }
            }
            .develop {
                background: url('../../assets/images/about/block/6.png') no-repeat left top;
                background-size: 100% 100%;
                width: 100%;
                padding: 2vw 15vw;
                display: flex;
                justify-content: flex-start;
                .lf {
                    flex: 3;
                }
                .rf {
                    flex: 4.5;
                    margin-top: 5vw;
                    .badge {
                        text-align: left;
                        margin: 0 0 2vw 3.5vw;
                    }
                    .box {
                        background: url('../../assets/images/about/bg_strategy.png') no-repeat center center;
                        background-size: 100%;
                        padding: 3.5vw 3.5vw 8vw;
                        p {
                            color: #353968;
                            line-height: 32px;
                            font-size: 1.2vw;
                            text-indent: 2em;
                            display: -webkit-box;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            -webkit-line-clamp: 5;
                            -webkit-box-orient: vertical;
                            margin-bottom: 6%;
                        }
                    }
                }
            }
            .other-items {
                background-color: white;
                width: 60vw;
                margin: 0 auto;
                padding: 3vw 0;

                ul {
                    display: flex;
                    justify-content: space-around;
                    li {
                        text-align: center;
                        img {
                            width: 12vw;
                            height: 12vw;
                        }
                        h2 {
                            font-size: 1.5vw;
                            font-weight: 400;
                            color: #353968;
                            margin: 16% 0;
                        }
                        p {
                            color: #353968;
                            font-size: 1.25vw;
                            margin: 1vw 0;
                        }
                    }
                }
            }
        }
    }

    .fontFn (@px, @attr: font-size) {
        @rem: (@px / 37.5);
            @{attr}: ~"@{rem}rem";
    }

    @media screen and (max-width: 750px) {
        #about {
            .content {
                width: 100%;
                z-index: 99;
                .top {
                    img {width: 88%;}
                }
                .intr {
                    >h2 {
                        .fontFn(14);
                    }
                    .lf {
                        float: left;
                        width: 65%;
                        line-height: 3.125vw;
                        h3 {
                            .fontFn(13);
                        }
                        p {
                            .fontFn(11);
                            -webkit-line-clamp: 5;
                        }
                    }
                    .rf {
                        float: right;
                        width: 35%;
                    }
                }
                .badge {
                    .fontFn(14);
                    &::after {
                        width: 3vw;
                        height: 3vw;
                    }
                }
                .culture {
                    padding: 2vw 5vw;

                    >h2 {
                        position: relative;
                        top: 5vw;
                        .fontFn(14);
                    }
                    .box {
                        position: relative;
                        top: 5vw;
                        img {width: 100%; max-width: 95vw;}
                    }
                }
                .honor {
                    padding: 6vw 2vw;

                    >h2 {
                        .fontFn(14);
                    }
                    .honor-list {
                        .fontFn(9);
                        .pic {
                            height: 25vw;

                            img {
                                width: 15vw;
                            }
                        }
                        p {
                            width: 14vw;
                            font-size: 18px;
                            margin: 1vw auto 0;
                            text-align: center;
                        }
                        .swiper-container {
                            padding: 0 1.85vw 3vw !important;
                        }
                        .swiper-slide:nth-child(even) {
                            margin-top: 2vw !important;
                        }
                        .swiper-button-next {
                            width: 1.3vw;
                            height: 2.29vw;
                            background-image: url('../../assets/images/about/icon_arrow_right.png');
                            background-size: 100%;
                        }
                        .swiper-button-prev {
                            width: 1.3vw;
                            height: 2.29vw;
                            background-image: url('../../assets/images/about/icon_arrow_left.png');
                            background-size: 100%;
                        }
                        .swiper-pagination {
                            bottom: 0;
                        }
                    }
                }

                .process {
                    padding: 5vw 0;

                    .process-content {
                        width: 90vw;
                        .lf {
                            width: 45vw;
                            height: 45vw;
                        }
                        .rf {
                            width: 45vw;
                            height: 45vw;
                            padding: 4vw;
                            color: #fff;
                            h1 {
                                .fontFn(18);
                            }
                            .point {
                                width: 1vw;
                                height: 1vw;
                                margin: 2vw 0;
                            }
                            p {
                                .fontFn(11);
                                line-height: 4vw;
                                -webkit-line-clamp: 6;
                            }
                        }
                    }
                    .time-line {
                        margin-top: 5vw;
                        padding: 3vw;
                        overflow-x: scroll;
                        &::-webkit-scrollbar {
                            display: none;
                        }
                        ul {
                            margin-left: @pointWidth;
                            &::after {
                                content: '';
                                display: block;
                                clear: both;
                            }
                            li {
                                float: left;
                                text-align: center;
                                width: @pointWidth;
                                padding-top: 5vw;
                                position: relative;
                                strong {
                                    font-size: 3.5vw;
                                    color: #8ED9FF;
                                    position: absolute;
                                    left: 0.5em;
                                    top: -0.1em;
                                    &.actived {
                                        left: 0;
                                        top: -0.5em;
                                        font-size: 5vw;
                                        color: #1BB2FF;
                                    }
                                }
                                .time-point {
                                    width: 100%;
                                    height: 0.2vw;
                                    background: #8ED9FF;
                                    position: relative;
                                    cursor: pointer;
                                    &::after {
                                        content: '';
                                        display: inline-block;
                                        width: 2.7vw;
                                        height: 2.7vw;
                                        background: #8ED9FF;
                                        border-radius: 50%;
                                        position: absolute;
                                        left: 50%;
                                        margin-left: -1.35vw;
                                        top: -1.35vw;
                                    }
                                }
                                .time-point-actived {
                                    &::after {
                                        background: #1BB2FF;
                                    }
                                }
                            }
                        }
                    }
                    .time-phone{
                        display: none;
                    }
                }
                .develop {
                    margin-top: -1px;
                    padding: 5vw;

                    .lf {
                        //background-color: cadetblue;
                        position: absolute;
                        bottom: 0;
                        right: 20vw;

                        img {width: 20vw}
                    }
                    .rf {
                        margin-top: unset;
                        .badge {
                            text-align: center;
                            margin: 0 0 2vw 3.5vw;
                        }
                        .box {
                            margin-top: 3vw;
                            padding: 10vw 12vw 15vw;
                            p {
                                color: #353968;
                                line-height: 32px;
                                .fontFn(12);
                                text-indent: 2em;
                                display: -webkit-box;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                -webkit-line-clamp: 5;
                                -webkit-box-orient: vertical;
                                margin-bottom: 6%;
                            }
                        }
                    }
                }
                .other-items {
                    background-color: white;
                    width: 70vw;
                    margin: 0 auto;
                    padding: 5vw 0 0 ;

                    ul {
                        display: flex;
                        justify-content: space-around;
                        li {
                            text-align: center;
                            img {
                                width: 18vw;
                                height: 18vw;
                            }
                            h2 {
                                .fontFn(12);
                                font-weight: 400;
                                color: #353968;
                                margin: 16% 0;
                            }
                        }
                    }
                }
            }
        }
    }
</style>